import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Sidebar, Menu } from 'semantic-ui-react';
import SignedInMenu from './SignedInMenu';
import SignedOutMenu from './SignedOutMenu';
import RuleOfEmployeeMenu from './RuleOfEmployeeMenu';
import CompetencyMenu from './CompetencyMenu';
import PaydocuMenu from './PaydocuMenu';
import NomuWorksMenu from './NomuWorksMenu';
import { getWorkerUserProfile } from '../../app/firestore/firestoreService4In';
import { listenToWorkerUsers } from '../profiles/profileActions';
import { dataFromSnapshotIdDate } from '../../app/firestore/firestoreService';
import RiskSuspectMenu from './RiskSuspectMenu';
import { freeComClient } from '../../app/common/util/util';
import WorkerManageMenu from './WorkerManageMenu';
import PaydocuAnnualLeaveMenu from './PaydocuAnnualLeaveMenu';
import PaydocuEvaluationMenu from './PaydocuEvaluationMenu';
import NoticeEtcMenu from './NoticeEtcMenu';
import CalculatorMenu from './CalculatorMenu';
import PriceIntroMenu from './PriceIntroMenu';
import PaydocuTableMenu from './PaydocuTableMenu';
// import ChatBotMenu from './ChatBotMenu';
// import { TbMessageChatbot } from 'react-icons/tb';
import { MdOutlinePriceCheck } from 'react-icons/md';
import { FaBookMedical } from 'react-icons/fa';
import { HiInformationCircle } from 'react-icons/hi';
import { GrVulnerability } from 'react-icons/gr';
import { FaHelmetSafety } from 'react-icons/fa6';
import { BsFillFileEarmarkRuledFill } from 'react-icons/bs';
import { VscHorizontalRule } from 'react-icons/vsc';
import { GrUserAdmin } from 'react-icons/gr';
import { CiCalculator1 } from 'react-icons/ci';
import { FcLeave } from 'react-icons/fc';
import { FaWonSign } from 'react-icons/fa';
import { PiUserListFill } from 'react-icons/pi';
import { MdSecurityUpdateGood } from 'react-icons/md';
import InsureRegister from './InsureRegister';
import CompanyContractMenu from './CompanyContractMenu';

const MySidebar = () => {
	const dispatch = useDispatch();
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { authenticated } = useSelector((state) => state.auth);
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);
	const { clientInfo, superAdminForFreeTrue } = useSelector(
		(state) => state.companyReducer
	);

	const authLevel =
		currentUserProfile?.authLevel > 100
			? currentUserProfile?.authLevel
			: clientInfo?.authLevel
			? clientInfo?.authLevel
			: currentUserProfile?.authLevel;
	const ceoName = clientInfo?.ceoName
		? clientInfo?.ceoName
		: currentUserProfile?.ceoName;
	const personalEvaluationYes =
		clientInfo?.personalEvaluation === 'BSC 기반 인사고과 기능 사용'
			? true
			: currentUserProfile?.personalEvaluation === 'BSC 기반 인사고과 기능 사용'
			? true
			: false;

	useEffect(() => {
		if (currentUserProfile) {
			// currentUserProfile이 존재할 때만 로직 실행
			let isMounted = true;
			const unSubscribe = getWorkerUserProfile({
				next: (snapshot) => {
					if (isMounted) {
						dispatch(
							listenToWorkerUsers(
								snapshot.docs.map((docSnapshot) =>
									dataFromSnapshotIdDate(docSnapshot)
								)
							)
						);
					}
				},
				error: (error) => console.log(error),
			});

			return () => {
				unSubscribe();
				isMounted = false;
			};
		}
	}, [dispatch, currentUserProfile]); // 의존성 배열에 currentUserProfile 추가

	// "AI노무사 매뉴얼 다운" 클릭 시 파일 다운로드 함수
	const downloadManual = () => {
		const link = document.createElement('a');
		link.href = '/AI 노무사 매뉴얼.pdf'; // 실제 PDF 파일의 경로로 변경
		link.download = 'AI_노무사_사용매뉴얼.pdf';
		link.click();
	};

	const isCoWorkSemusa =
		currentUserProfile?.isAgent === '스폰서' &&
		currentUserProfile.agentType === 'bs세무사'
			? true
			: false;
	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;
	const retirementPayDC =
		clientInfo?.retirementPay === 'DC형 퇴직연금'
			? true
			: currentUserProfile?.retirementPay === 'DC형 퇴직연금'
			? true
			: false;

	const isSuperAdmin = currentUserProfile?.authLevel >= 110;

	const viewId = clientInfo?.id || currentUserProfile?.id;
	const isWorker = false;

	const roeConsulting = clientInfo?.roeConsulting
		? clientInfo?.roeConsulting
		: currentUserProfile?.roeConsulting;
	const rlcExcuteDueDate = clientInfo?.rlcExcuteDueDate
		? clientInfo?.rlcExcuteDueDate
		: currentUserProfile?.rlcExcuteDueDate;
	const rOEBizSecretConsulting = clientInfo?.rOEBizSecretConsulting
		? clientInfo?.rOEBizSecretConsulting
		: currentUserProfile?.rOEBizSecretConsulting;

	const roeExcuteDueDate = clientInfo?.roeExcuteDueDate
		? clientInfo?.roeExcuteDueDate
		: currentUserProfile?.roeExcuteDueDate;
	const ruleLaborCouncilConsulting = clientInfo?.ruleLaborCouncilConsulting
		? clientInfo?.ruleLaborCouncilConsulting
		: currentUserProfile?.ruleLaborCouncilConsulting;
	const bizSecretExcuteDueDate = clientInfo?.bizSecretExcuteDueDate
		? clientInfo?.bizSecretExcuteDueDate
		: currentUserProfile?.bizSecretExcuteDueDate;

	return (
		<Sidebar
			as={Menu}
			// animation='overlay'
			icon='labeled'
			inverted
			vertical
			visible
			// width='wide'
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'flex-start',
				width: '15%',
				height: '100vh',
				overflowY: 'auto',
			}}>
			<Menu.Item className='menu-item' header as={NavLink} exact to='/'>
				<img src='logo.png' alt='logo' />
			</Menu.Item>
			<div
				style={{
					flex: 1,
					overflowY: 'auto',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
				}}>
				{authLevel >= 10 || !authenticated ? null : (
					<PriceIntroMenu MdOutlinePriceCheck={MdOutlinePriceCheck} />
				)}
				{/* "AI노무사 매뉴얼 다운" 메뉴 추가 */}
				<Menu.Item onClick={downloadManual} style={{ color: 'yellow' }}>
					<FaBookMedical className='icon-spacing' />
					매뉴얼 다운로드
				</Menu.Item>
				{authLevel >= 10 ? (
					<>
						<WorkerManageMenu
							authLevel={authLevel}
							ceoName={ceoName}
							clientReqs4In={clientReqs4In}
							superAdminForFreeTrue={superAdminForFreeTrue}
						/>
						<PaydocuMenu FaWonSign={FaWonSign} />
						<PaydocuTableMenu
							PiUserListFill={PiUserListFill}
							companyName={companyName}
							retirementPayDC={retirementPayDC}
						/>
						<InsureRegister
							MdSecurityUpdateGood={MdSecurityUpdateGood}
							currentUserProfile={currentUserProfile}
						/>
						<PaydocuAnnualLeaveMenu
							currentUserProfile={currentUserProfile}
							clientInfo={clientInfo}
							FcLeave={FcLeave}
						/>
						<CompetencyMenu GrVulnerability={GrVulnerability} />
						<RiskSuspectMenu FaHelmetSafety={FaHelmetSafety} />
						<PaydocuEvaluationMenu
							authLevel={authLevel}
							personalEvaluationYes={personalEvaluationYes}
							VscHorizontalRule={VscHorizontalRule}
						/>
						<RuleOfEmployeeMenu
							BsFillFileEarmarkRuledFill={BsFillFileEarmarkRuledFill}
							isCoWorkSemusa={isCoWorkSemusa}
							freeComClient5={freeComClient(authLevel, ceoName)}
							viewId={viewId}
							isWorker={isWorker}
							roeConsulting={roeConsulting}
							rlcExcuteDueDate={rlcExcuteDueDate}
							rOEBizSecretConsulting={rOEBizSecretConsulting}
							roeExcuteDueDate={roeExcuteDueDate}
							ruleLaborCouncilConsulting={ruleLaborCouncilConsulting}
							bizSecretExcuteDueDate={bizSecretExcuteDueDate}
						/>
					</>
				) : null}
				<CalculatorMenu CiCalculator1={CiCalculator1} />
				<NoticeEtcMenu HiInformationCircle={HiInformationCircle} />
				<br />
				<br />
				<PriceIntroMenu MdOutlinePriceCheck={MdOutlinePriceCheck} />
				{authLevel >= 100 && (
					<CompanyContractMenu profile={currentUserProfile} />
				)}

				{/* <ChatBotMenu
					TbMessageChatbot={TbMessageChatbot}
					authLevel={authLevel}
				/> */}
			</div>
			<div>
				{authLevel >= 100 ? (
					<NomuWorksMenu
						GrUserAdmin={GrUserAdmin}
						isSuperAdmin={isSuperAdmin}
					/>
				) : null}
				{authenticated ? <SignedInMenu /> : <SignedOutMenu />}
			</div>
		</Sidebar>
	);
};

export default MySidebar;
