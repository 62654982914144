import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
// import { httpsCallable } from 'firebase/functions';
// import { functions } from '../../../../app/config/firebase';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { Grid, Table } from 'semantic-ui-react';
import {
	dayOrdIncludeComment4,
	isInterInWorkerF,
	isInterOutWorkerF,
	numStringToNumber,
	roundUp,
} from '../../../../app/common/util/util';
import { listenToClientReq4InFromFirestore } from '../../../../app/firestore/firestoreService4In';
import useFirestoreDoc from '../../../../app/hooks/useFirestoreDoc';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
	listenToSelectedClientReq4In,
} from '../clientReqActions4In';
import ClientReqDetailedHeader4In from './ClientReqDetailedHeader4In';
import ClientReqDLCJudgeWageTable from './clientReqDetailedLCDetail/ClientReqDLCJudgeWageTable';
import ClientReqDLCHeadWageTable from './clientReqDetailedLCDetail/ClientReqDLCHeadWageTable';
import ClientReqDLCWageTablePayDetail from './clientReqDetailedLCDetail/clientReqDLCWageTableDetail/payDetail/ClientReqDLCWageTablePayDetail';
import ClientReqDLCWageTableTimeDetail from './clientReqDetailedLCDetail/clientReqDLCWageTableDetail/timeDetail/ClientReqDLCWageTableTimeDetail';
import { decrypto } from '../../../../app/firestore/firestoreService';
import moment from 'moment';
import ClientReqDLCWageTableAddWageDetail from './clientReqDetailedLCDetail/clientReqDLCWageTableDetail/timeDetail/ClientReqDLCWageTableAddWageDetail';
import ClientReqDLCWageTableAddDeductWageDetail from './clientReqDetailedLCDetail/clientReqDLCWageTableDetail/timeDetail/ClientReqDLCWageTableAddDeductWageDetail';
import {
	clearFinalRetireHealthCareRecalFee,
	clearFinalRetireOldCareRecalFee,
	clearPaydocu,
	fetchAllPaydocusFormFirestoreService4In,
} from '../../../companyManage/companyActions';
import { toast } from 'react-toastify';
import { openModal } from '../../../../app/common/modals/modalReducer';
import HrManage from './HrManage';
import {
	useLocation,
	useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import PaydocuShortCut from '../../../companyManage/paydocuDashboard/PaydocuShortCut';
import { extendMoment } from 'moment-range';

import axios from 'axios';
import {
	collection,
	deleteField,
	doc,
	getDocs,
	query,
	updateDoc,
	where,
} from 'firebase/firestore';
import { db } from '../../../../app/config/firebase';
import ClientWageTableTopInfo from './ClientWageTableTopInfo';
import ClientWageTableTopButton from './ClientWageTableTopButton';
import ClientWageTableTop1BasicInfo from './ClientWageTableTop1BasicInfo';
import ClientWageTableTop2TimeDeduct from './ClientWageTableTop2TimeDedect';
import ClientWageTableTop3TimeExplain from './ClientWageTableTop3TimeExplain';
import ClientWageTableTop4DeductExplain from './ClientWageTableTop4DeductExplain';
import ClientWageTableTop5InsureExplain from './ClientWageTableTop5InsureExplain';
import ClientWageTableTop6EndExplain from './ClientWageTableTop6EndExplain';

const API_BASE_URL = process.env.REACT_APP_API_SERVER_URL; // 실제 서버 URL로 변경하세요.
// const API_BASE_URL = 'http://localhost:5000';

export default function ClientRDWageTable({ match }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const paramYearMonth = queryParams.get('yearMonth');

	const { selectedClientReq4In, clientReqs4In } = useSelector(
		(state) => state.clientReq4InStore
	);
	const {
		paydocus,
		filter,
		finalRetireHealthCareRecalFee,
		finalRetireOldCareRecalFee,
		paydocuCopy,
		paydocuFix,
		clientInfo,
	} = useSelector((state) => state.companyReducer);
	const { loading, error } = useSelector((state) => state.async);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const [valueTaxWageSum, setValueTaxWageSum] = useState(0);
	const [maternityFinalDedectWage, setMaternityFinalDedectWage] = useState([
		'',
		'',
		'',
		'',
		false,
		Array(0),
		null,
		'',
		0,
	]);

	// function handelInputChange(e) {
	// 	const { name, value } = e.target;
	// 	setValues({ ...values, [name]: value });
	// }

	function handelInputChange(e) {
		let { name, value } = e.target;

		// 콤마 제거 후 숫자로 변환
		value = value.replace(/,/g, '');

		// 숫자인 경우 천 단위 콤마 추가
		if (!isNaN(value) && value !== '') {
			value = new Intl.NumberFormat().format(Number(value));
		}

		// thisMonth 필드를 처리할 때는 두 자리로 맞춤
		if (name === 'thisMonth') {
			value = value.padStart(2, '0'); // 두 자리로 맞추기
		}

		setValues((prevValues) => ({
			...prevValues,
			[name]: value, // 현재 필드 업데이트
		}));
	}

	useFirestoreDoc({
		query: () => listenToClientReq4InFromFirestore(match.params.id),
		data: (selectedClientReq4In) =>
			dispatch(listenToSelectedClientReq4In(selectedClientReq4In)),
		deps: [match.params.id, dispatch],
	});

	useEffect(() => {
		dispatch(fetchClientReqs4In(filter)).then(() => {});
		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	const retiredWorker = selectedClientReq4In?.retiredDate ? true : false;
	const retiredWorkerPaydocu = paydocus.slice(-1)[0];

	let selectedPaydocuFirst = retiredWorker ? retiredWorkerPaydocu : paydocus[0];

	const dataOfFinalRetireHealthCareRecalFee = finalRetireHealthCareRecalFee;
	const dataOfFinalRetireOldCareRecalFee = finalRetireOldCareRecalFee;
	const healthInsureOver60 =
		selectedPaydocuFirst?.deductTaxAnd4In?.healthInsureOver60;

	const decryptoData = {};
	for (const prop in selectedClientReq4In) {
		decryptoData[prop] =
			prop === 'workerSocialNumberFront'
				? // || prop === "workerName"
				  decrypto(selectedClientReq4In[prop], String(process.env.CRYPTO_KEY))
				: selectedClientReq4In[prop];
	}

	const [values, setValues] = useState({
		id: match.params.id,
		workingDays: '',
		usedAnnualLeaves: '',
		usedAnnualLeaveDates: '',
		thisMonth: location.pathname.startsWith('/clientRDWageTable')
			? format(new Date(), 'MM')
			: '',
		thisYear: location.pathname.startsWith('/clientRDWageTable')
			? format(new Date(), 'yyyy')
			: '',
		plusMonthOWT: '',
		plusMonthNWT: '',
		plusMonthHoliBasicTime: '',
		plusMonthOWTHoli: '',
		plusMonthNWTHoli: '',
		cAWage5: '',
		cAWEName5: '',
		cAWage5Comment: '',
		cAWage6: '',
		cAWEName6: '',
		cAWage6Comment: '',
		cAWage7: '',
		cAWEName7: '',
		cAWage7Comment: '',
		userFixedCAWageTF1: '',
		userFixedCAWageTF1Name: '',
		userFixedCAWageTF1Comment: '',
		userFixedCAWageTF2: '',
		userFixedCAWageTF2Name: '',
		userFixedCAWageTF2Comment: '',
		userFixedCAWageTF3: '',
		userFixedCAWageTF3Name: '',
		userFixedCAWageTF3Comment: '',
		userFixedCAWageTF4: '',
		userFixedCAWageTF4Name: '',
		userFixedCAWageTF4Comment: '',
		userFixedCAWage1: '',
		userFixedCAWage1Name: '',
		userFixedCAWage1Comment: '',
		userFixedCAWage2: '',
		userFixedCAWage2Name: '',
		userFixedCAWage2Comment: '',
		userFixedCAWage3: '',
		userFixedCAWage3Name: '',
		userFixedCAWage3Comment: '',
		userFixedCAWage4: '',
		userFixedCAWage4Name: '',
		userFixedCAWage4Comment: '',
		userFixedTax: '',
		userFixedTaxComment: '',
		userFixedRegionTax: '',
		userFixedRegionTaxComment: '',
		userFixedHealthCare: '',
		userFixedHealthCareComment: '',
		userFixedOldCare: '',
		userFixedOldCareComment: '',
		userFixedPension: '',
		userFixedPensionComment: '',
		userFixedNoJobInsurance: '',
		userFixedNoJobInsuranceComment: '',
		absentDays: '',
		absentDates: '',
		absentPaidHoliDays: '',
		absentPaidHoliDates: '',
		absentTimes: '',
		absentTimeDates: '',
		deductEtcName1: '',
		deductEtcAWage1: '',
		deductEtcWage1Comment: '',
		deductEtcName2: '',
		deductEtcAWage2: '',
		deductEtcWage2Comment: '',
		deductEtcName3: '',
		deductEtcAWage3: '',
		deductEtcWage3Comment: '',
		jobImproveProposal: '',
		qualityOfWork: '',
		amounOfWork: '',
		supportColleague: '',
		attenanceManage: '',
		supportCumstomer: '',
		selfStudy: '',
		reportAttitude: '',
		hrEvaluationComment: '',
		jobImproveProposalComments: '',
		qualityOfWorkComments: '',
		amounOfWorkComments: '',
		supportColleagueComments: '',
		attenanceManageComments: '',
		supportCumstomerComments: '',
		selfStudyComments: '',
		reportAttitudeComments: '',
		plusRegularBonus: '',
		plusIrregularBonus: '',
		plusUnusedAnnualLeavesWage: '',
		plusUnusedAnnualLeavesDays: '',
		plusRetiredUnusedAnnualLeavesWage: '',
		plusSpecialRandomWage: '',
		plusPersonalIncentive: '',
		plusLastMonthAverWage: '',
		plusRegularBonusComment: '',
		plusIrregularBonusComment: '',
		plusUnusedAnnualLeavesWageComment: '',
		plusLastMonthAverWageComment: '',
		plusPersonalIncentiveComment: '',
		perDayMealTaxFree: '',
		plusIrregularDayMealTFDays: '',
		plusIrregularDayMealTFWage: '',
		plusDeductHealthCare: '',
		plusDeductOldCare: '',
		plusDeductWorkTax: '',
		plusDeductWorkRegionTax: '',
		plusDeductDuru: '',
		plusMinus4Ins: '',
		plusDeductWorkerCompanyLoan: '',
		finalRetireHealthCareRecalFee: '',
		finalRetireOldCareRecalFee: '',
		plusDeductHealthCareComment: '',
		plusDeductOldCareComment: '',
		plusDeductWorkTaxComment: '',
		plusDeductWorkRegionTaxComment: '',
		plusDeductDuruComment: '',
		plusMinus4InsComment: '',
		plusDeductWorkerCompanyLoanComment: '',
	});

	useEffect(() => {
		setValues((prevValues) => ({
			...prevValues,
			thisMonth: String(prevValues.thisMonth).padStart(2, '0'), // 항상 두 자리로 유지
		}));
	}, [values.thisMonth]);

	const yearMonth = values.thisYear + values.thisMonth;

	useEffect(() => {
		const storedYear = localStorage.getItem('thisYear');
		const storedMonth = localStorage.getItem('thisMonth');

		if (storedYear && storedMonth) {
			setValues((prevValues) => ({
				...prevValues,
				thisYear: storedYear,
				thisMonth: storedMonth,
			}));
		} else if (paramYearMonth) {
			setValues((prevValues) => ({
				...prevValues,
				thisYear: paramYearMonth.slice(0, 4),
				thisMonth: paramYearMonth.slice(4),
			}));
		} else {
			const currentYear = format(new Date(), 'yyyy');
			const currentMonth = format(new Date(), 'MM');
			setValues((prevValues) => ({
				...prevValues,
				thisYear: currentYear,
				thisMonth: currentMonth,
			}));
		}
	}, [paramYearMonth]);

	useEffect(() => {
		if (values.thisYear && values.thisMonth) {
			localStorage.setItem('thisYear', values.thisYear);
			localStorage.setItem('thisMonth', values.thisMonth);
		}
	}, [values.thisYear, values.thisMonth]);

	useEffect(() => {
		if (
			selectedClientReq4In?.id &&
			location.pathname.startsWith('/manageClientRDWageTable')
		) {
			dispatch(
				fetchAllPaydocusFormFirestoreService4In(
					'isWorker',
					selectedClientReq4In.id,
					values.thisYear + values.thisMonth
				)
			).then(() => {});
			return () => {
				dispatch(clearPaydocu());
			};
		}
	}, [
		dispatch,
		selectedClientReq4In?.id,
		location.pathname,
		values.thisYear,
		values.thisMonth,
	]);

	useEffect(() => {
		if (paramYearMonth) {
			setValues((prevValues) => ({
				...prevValues,
				thisYear: paramYearMonth.slice(0, 4),
				thisMonth: paramYearMonth.slice(4),
			}));
		}
	}, [paramYearMonth]);

	useEffect(() => {
		if (
			selectedClientReq4In?.id &&
			location.pathname.startsWith('/manageClientRDWageTable')
		) {
			dispatch(
				fetchAllPaydocusFormFirestoreService4In(
					'isWorker',
					selectedClientReq4In.id,
					yearMonth
				)
			).then(() => {});
			return () => {
				dispatch(clearPaydocu());
			};
		}
	}, [dispatch, selectedClientReq4In?.id, location.pathname, yearMonth]);

	useEffect(() => {
		if (selectedPaydocuFirst) {
			setValues((prevValues) => ({
				...prevValues,
				usedAnnualLeaves: +selectedPaydocuFirst?.usedAnnualLeaves || '',
				usedAnnualLeaveDates: selectedPaydocuFirst?.usedAnnualLeaveDates || '',
				thisMonth:
					selectedPaydocuFirst?.info?.month || format(new Date(), 'MM'),
				thisYear:
					selectedPaydocuFirst?.info?.year || format(new Date(), 'yyyy'),
				plusMonthOWT: +selectedPaydocuFirst?.lawOverTimeWage.plusOTime || '',
				plusMonthNWT: +selectedPaydocuFirst?.lawOverTimeWage.plusNTime || '',
				plusMonthHoliBasicTime:
					+selectedPaydocuFirst?.lawOverTimeWage.plusHBTime || '',
				plusMonthOWTHoli:
					+selectedPaydocuFirst?.lawOverTimeWage.plusHOTime || '',
				plusMonthNWTHoli:
					+selectedPaydocuFirst?.lawOverTimeWage.plusHNTime || '',
				cAWage5: +selectedPaydocuFirst?.addAverWage.aWage5Wage || '',
				cAWEName5: selectedPaydocuFirst?.addAverWage.aWEName5 || '',
				cAWage5Comment: selectedPaydocuFirst?.addAverWage.cAWage5Comment || '',
				cAWage6: +selectedPaydocuFirst?.addAverWage.aWage6Wage || '',
				cAWEName6: selectedPaydocuFirst?.addAverWage.aWEName6 || '',
				cAWage6Comment: selectedPaydocuFirst?.addAverWage.cAWage6Comment || '',
				cAWage7: +selectedPaydocuFirst?.addAverWage.aWage7Wage || '',
				cAWEName7: selectedPaydocuFirst?.addAverWage.aWEName7 || '',
				cAWage7Comment: selectedPaydocuFirst?.addAverWage.cAWage7Comment || '',
				userFixedCAWageTF1:
					+selectedPaydocuFirst?.fixedWage.userFixedCAWageTF1 || '',
				userFixedCAWageTF1Name:
					selectedPaydocuFirst?.fixedWage.userFixedCAWageTF1Name || '',
				userFixedCAWageTF1Comment:
					selectedPaydocuFirst?.fixedWage.userFixedCAWageTF1Comment || '',
				userFixedCAWageTF2:
					+selectedPaydocuFirst?.fixedWage.userFixedCAWageTF2 || '',
				userFixedCAWageTF2Name:
					selectedPaydocuFirst?.fixedWage.userFixedCAWageTF2Name || '',
				userFixedCAWageTF2Comment:
					selectedPaydocuFirst?.fixedWage.userFixedCAWageTF2Comment || '',
				userFixedCAWageTF3:
					+selectedPaydocuFirst?.fixedWage.userFixedCAWageTF3 || '',
				userFixedCAWageTF3Name:
					selectedPaydocuFirst?.fixedWage.userFixedCAWageTF3Name || '',
				userFixedCAWageTF3Comment:
					selectedPaydocuFirst?.fixedWage.userFixedCAWageTF3Comment || '',
				userFixedCAWageTF4:
					+selectedPaydocuFirst?.fixedWage.userFixedCAWageTF4 || '',
				userFixedCAWageTF4Name:
					selectedPaydocuFirst?.fixedWage.userFixedCAWageTF4Name || '',
				userFixedCAWageTF4Comment:
					selectedPaydocuFirst?.fixedWage.userFixedCAWageTF4Comment || '',
				userFixedCAWage1:
					+selectedPaydocuFirst?.fixedWage.userFixedCAWage1 || '',
				userFixedCAWage1Name:
					selectedPaydocuFirst?.fixedWage.userFixedCAWage1Name || '',
				userFixedCAWage1Comment:
					selectedPaydocuFirst?.fixedWage.userFixedCAWage1Comment || '',
				userFixedCAWage2:
					+selectedPaydocuFirst?.fixedWage.userFixedCAWage2 || '',
				userFixedCAWage2Name:
					selectedPaydocuFirst?.fixedWage.userFixedCAWage2Name || '',
				userFixedCAWage2Comment:
					selectedPaydocuFirst?.fixedWage.userFixedCAWage2Comment || '',
				userFixedCAWage3:
					+selectedPaydocuFirst?.fixedWage.userFixedCAWage3 || '',
				userFixedCAWage3Name:
					selectedPaydocuFirst?.fixedWage.userFixedCAWage3Name || '',
				userFixedCAWage3Comment:
					selectedPaydocuFirst?.fixedWage.userFixedCAWage3Comment || '',
				userFixedCAWage4:
					+selectedPaydocuFirst?.fixedWage.userFixedCAWage4 || '',
				userFixedCAWage4Name:
					selectedPaydocuFirst?.fixedWage.userFixedCAWage4Name || '',
				userFixedCAWage4Comment:
					selectedPaydocuFirst?.fixedWage.userFixedCAWage4Comment || '',
				userFixedTax:
					selectedPaydocuFirst?.deductTaxAnd4In.userFixedTax === 0
						? 0
						: selectedPaydocuFirst?.deductTaxAnd4In.userFixedTax || '',
				userFixedTaxComment:
					selectedPaydocuFirst?.deductTaxAnd4In.userFixedTaxComment || '',
				userFixedRegionTax:
					selectedPaydocuFirst?.deductTaxAnd4In.userFixedRegionTax === 0
						? 0
						: selectedPaydocuFirst?.deductTaxAnd4In.userFixedRegionTax || '',
				userFixedRegionTaxComment:
					selectedPaydocuFirst?.deductTaxAnd4In.userFixedRegionTaxComment || '',
				userFixedHealthCare:
					+selectedPaydocuFirst?.deductTaxAnd4In.userFixedHealthCare || '',
				userFixedHealthCareComment:
					selectedPaydocuFirst?.deductTaxAnd4In.userFixedHealthCareComment ||
					'',
				userFixedOldCare:
					+selectedPaydocuFirst?.deductTaxAnd4In.userFixedOldCare || '',
				userFixedOldCareComment:
					selectedPaydocuFirst?.deductTaxAnd4In.userFixedOldCareComment || '',
				userFixedPension:
					+selectedPaydocuFirst?.deductTaxAnd4In.userFixedPension || '',
				userFixedPensionComment:
					selectedPaydocuFirst?.deductTaxAnd4In.userFixedPensionComment || '',
				userFixedNoJobInsurance:
					+selectedPaydocuFirst?.deductTaxAnd4In.userFixedNoJobInsurance || '',
				userFixedNoJobInsuranceComment:
					selectedPaydocuFirst?.deductTaxAnd4In
						.userFixedNoJobInsuranceComment || '',
				absentDays: +selectedPaydocuFirst?.absentMinusWage.absentDays || '',
				absentDates: selectedPaydocuFirst?.absentMinusWage.absentDates || '',
				absentPaidHoliDays:
					+selectedPaydocuFirst?.absentMinusWage.absentPaidHoliDays || '',
				absentPaidHoliDates:
					selectedPaydocuFirst?.absentMinusWage.absentPaidHoliDates || '',
				absentTimes: +selectedPaydocuFirst?.absentMinusWage.absentTimes || '',
				absentTimeDates:
					selectedPaydocuFirst?.absentMinusWage.absentTimeDates || '',
				deductEtcName1:
					selectedPaydocuFirst?.deductTaxAnd4In.deductEtcName1 || '',
				deductEtcAWage1:
					+selectedPaydocuFirst?.deductTaxAnd4In.numDeductEtcAWage1 || '',
				deductEtcWage1Comment:
					selectedPaydocuFirst?.deductTaxAnd4In.deductEtcWage1Comment || '',
				deductEtcName2:
					selectedPaydocuFirst?.deductTaxAnd4In.deductEtcName2 || '',
				deductEtcAWage2:
					+selectedPaydocuFirst?.deductTaxAnd4In.numDeductEtcAWage2 || '',
				deductEtcWage2Comment:
					selectedPaydocuFirst?.deductTaxAnd4In.deductEtcWage2Comment || '',
				deductEtcName3:
					selectedPaydocuFirst?.deductTaxAnd4In.deductEtcName3 || '',
				deductEtcAWage3:
					+selectedPaydocuFirst?.deductTaxAnd4In.numDeductEtcAWage3 || '',
				deductEtcWage3Comment:
					selectedPaydocuFirst?.deductTaxAnd4In.deductEtcWage3Comment || '',
				jobImproveProposal:
					selectedPaydocuFirst?.hrEvaluationInfo?.jobImproveProposal || '',
				qualityOfWork:
					selectedPaydocuFirst?.hrEvaluationInfo?.qualityOfWork || '',
				amounOfWork: selectedPaydocuFirst?.hrEvaluationInfo?.amounOfWork || '',
				supportColleague:
					selectedPaydocuFirst?.hrEvaluationInfo?.supportColleague || '',
				attenanceManage:
					selectedPaydocuFirst?.hrEvaluationInfo?.attenanceManage || '',
				supportCumstomer:
					selectedPaydocuFirst?.hrEvaluationInfo?.supportCumstomer || '',
				selfStudy: selectedPaydocuFirst?.hrEvaluationInfo?.selfStudy || '',
				reportAttitude:
					selectedPaydocuFirst?.hrEvaluationInfo?.reportAttitude || '',
				hrEvaluationComment:
					selectedPaydocuFirst?.hrEvaluationInfo?.hrEvaluationComment || '',
				jobImproveProposalComments:
					selectedPaydocuFirst?.hrEvaluationInfo?.jobImproveProposalComments ||
					'',
				qualityOfWorkComments:
					selectedPaydocuFirst?.hrEvaluationInfo?.qualityOfWorkComments || '',
				amounOfWorkComments:
					selectedPaydocuFirst?.hrEvaluationInfo?.amounOfWorkComments || '',
				supportColleagueComments:
					selectedPaydocuFirst?.hrEvaluationInfo?.supportColleagueComments ||
					'',
				attenanceManageComments:
					selectedPaydocuFirst?.hrEvaluationInfo?.attenanceManageComments || '',
				supportCumstomerComments:
					selectedPaydocuFirst?.hrEvaluationInfo?.supportCumstomerComments ||
					'',
				selfStudyComments:
					selectedPaydocuFirst?.hrEvaluationInfo?.selfStudyComments || '',
				reportAttitudeComments:
					selectedPaydocuFirst?.hrEvaluationInfo?.reportAttitudeComments || '',
				plusRegularBonus:
					+selectedPaydocuFirst?.addAverWage.plusRegularBonus || '',
				plusIrregularBonus:
					+selectedPaydocuFirst?.addAverWage.plusIrregularBonus || '',
				plusUnusedAnnualLeavesWage:
					+selectedPaydocuFirst?.addAverWage.plusUnusedAnnualLeavesWage || '',
				plusUnusedAnnualLeavesDays:
					+selectedPaydocuFirst?.addAverWage.plusUnusedAnnualLeavesDays || '',
				plusRetiredUnusedAnnualLeavesWage:
					+selectedPaydocuFirst?.plusRetiredUnusedAnnualLeaves || '',
				plusSpecialRandomWage:
					+selectedPaydocuFirst?.plusSpecialRandomWage || '',
				plusPersonalIncentive:
					+selectedPaydocuFirst?.addAverWage.plusPersonalIncentive || '',
				plusLastMonthAverWage:
					+selectedPaydocuFirst?.addAverWage.plusLastMonthAverWage || '',
				plusRegularBonusComment:
					selectedPaydocuFirst?.addAverWage.plusRegularBonusComment || '',
				plusIrregularBonusComment:
					selectedPaydocuFirst?.addAverWage.plusIrregularBonusComment || '',
				plusUnusedAnnualLeavesWageComment:
					selectedPaydocuFirst?.addAverWage.plusUnusedAnnualLeavesWageComment ||
					'',
				plusLastMonthAverWageComment:
					selectedPaydocuFirst?.addAverWage.plusLastMonthAverWageComment || '',
				plusPersonalIncentiveComment:
					selectedPaydocuFirst?.addAverWage.plusPersonalIncentiveComment || '',
				perDayMealTaxFree:
					+selectedPaydocuFirst?.addAverWage.perDayMealTaxFree || '',
				plusIrregularDayMealTFDays:
					+selectedPaydocuFirst?.addAverWage.plusIrregularDayMealTFDays || '',
				plusIrregularDayMealTFWage:
					+selectedPaydocuFirst?.addAverWage.plusIrregularDayMealTFWage || '',
				plusDeductHealthCare:
					+selectedPaydocuFirst?.deductTaxAnd4In.plusDeductHealthCare || '',
				plusDeductOldCare:
					+selectedPaydocuFirst?.deductTaxAnd4In.plusDeductOldCare || '',
				plusDeductWorkTax:
					+selectedPaydocuFirst?.deductTaxAnd4In.plusDeductWorkTax || '',
				plusDeductWorkRegionTax:
					+selectedPaydocuFirst?.deductTaxAnd4In.plusDeductWorkRegionTax || '',
				plusDeductDuru:
					+selectedPaydocuFirst?.deductTaxAnd4In.plusDeductDuru || '',
				plusMinus4Ins:
					+selectedPaydocuFirst?.deductTaxAnd4In.plusMinus4Ins || '',
				plusDeductWorkerCompanyLoan:
					+selectedPaydocuFirst?.deductTaxAnd4In.plusDeductWorkerCompanyLoan ||
					'',
				finalRetireHealthCareRecalFee:
					finalRetireHealthCareRecalFee ||
					selectedPaydocuFirst?.deductTaxAnd4In.finalRetireHealthCareRecalFee ||
					'',
				finalRetireOldCareRecalFee:
					finalRetireOldCareRecalFee ||
					selectedPaydocuFirst?.deductTaxAnd4In.finalRetireOldCareRecalFee ||
					'',
				plusDeductHealthCareComment:
					selectedPaydocuFirst?.deductTaxAnd4In.plusDeductHealthCareComment ||
					'',
				plusDeductOldCareComment:
					selectedPaydocuFirst?.deductTaxAnd4In.plusDeductOldCareComment || '',
				plusDeductWorkTaxComment:
					selectedPaydocuFirst?.deductTaxAnd4In.plusDeductWorkTaxComment || '',
				plusDeductWorkRegionTaxComment:
					selectedPaydocuFirst?.deductTaxAnd4In
						.plusDeductWorkRegionTaxComment || '',
				plusDeductDuruComment:
					selectedPaydocuFirst?.deductTaxAnd4In.plusDeductDuruComment || '',
				plusMinus4InsComment:
					selectedPaydocuFirst?.deductTaxAnd4In.plusMinus4InsComment || '',
				plusDeductWorkerCompanyLoanComment:
					selectedPaydocuFirst?.deductTaxAnd4In
						.plusDeductWorkerCompanyLoanComment || '',
			}));
		}
	}, [
		selectedPaydocuFirst,
		finalRetireHealthCareRecalFee,
		finalRetireOldCareRecalFee,
		match.params.id,
	]); //

	const [inputTax, setInputTax] = useState(true);
	const [initialTax, setInitialTax] = useState(false);
	const [showAlert, setShowAlert] = useState(false);

	//initialValues에 있으면 undefine되어 빼론거
	const hourOrdinaryWage = selectedClientReq4In?.lawBase?.hourOrdinaryWage;
	const worker = selectedClientReq4In?.worker?.name;
	const isExceptTimeLawWorker = selectedClientReq4In?.isExceptTimeLawWorker;

	const monthLBTime =
		selectedClientReq4In?.numOfWorkers >= 5
			? roundUp(selectedClientReq4In?.lawBase?.monthLBTime)
			: roundUp(selectedClientReq4In?.mTPCTime);

	const monthOWT =
		selectedClientReq4In?.numOfWorkers < 5
			? 0
			: selectedClientReq4In?.realWorkTime?.monthOWT;
	const monthNWT = selectedClientReq4In?.realWorkTime?.monthNWT;
	const monthHoliBasicTime =
		selectedClientReq4In?.numOfWorkers < 5
			? 0
			: selectedClientReq4In?.realWorkTime?.monthHoliBasicTime;
	const monthOWTHoli =
		selectedClientReq4In?.numOfWorkers < 5
			? 0
			: selectedClientReq4In?.realWorkTime?.monthNOWTHoli;
	const monthNWTHoli = selectedClientReq4In?.realWorkTime?.monthNWTHoli;

	//기초관리
	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const perDayMealTaxFree = clientInfo?.perDayMealTaxFree
		? +clientInfo?.perDayMealTaxFree
		: currentUserProfile?.perDayMealTaxFree
		? +currentUserProfile?.perDayMealTaxFree
		: 0;

	let today = new Date();
	const numOfWorkers = selectedClientReq4In?.numOfWorkers;
	const mTPCTime = selectedClientReq4In?.calWorkTime?.mTPCTime;

	const centerStyle = { textAlign: 'center', width: '12.4%', height: '40px' };
	const centerStyleInOutBack = {
		textAlign: 'center',
		width: '12.4%',
		height: '40px',
		backgroundColor: '#A9A9A9',
	};
	const centerStyleBlue = {
		textAlign: 'center',
		width: '12.4%',
		height: '40px',
		color: 'blue',
	};
	const centerStyleTeal = {
		textAlign: 'center',
		width: '12.4%',
		height: '40px',
		color: 'teal',
	};

	const inputTimeStyle = {
		border: 'none',
		borderRight: '0px',
		borderTop: '0px',
		borderLeft: '0px',
		borderBottom: '0px',
		textAlign: 'center',
		width: '5rem',
	};
	const inputWageStyle = {
		border: 'none',
		borderRight: '0px',
		borderTop: '0px',
		borderLeft: '0px',
		borderBottom: '0px',
		textAlign: 'right',
		width: '7rem',
	};
	const inputCommentStyle = {
		resize: 'none',
		height: '20px',
		border: 'none',
		borderRight: '0px',
		borderTop: '0px',
		borderLeft: '0px',
		borderBottom: '0px',
		textAlign: 'left',
		width: '50rem',
	};

	// 11월24일-공통-삼성자동차
	const inputCommentStyleWide = {
		resize: 'none',
		height: '70px',
		border: 'none',
		borderRight: '0px',
		borderTop: '0px',
		borderLeft: '0px',
		borderBottom: '0px',
		textAlign: 'left',
		width: '50rem',
	};
	// --------------

	const weekLBTime = selectedClientReq4In?.lawBase?.weekLBTime;

	//노무사는 노무사가 입력한 회사로 회사이름 설정 가능하고, 유저는 본인 회원가입한 상호로만 회사이름 설정됨.
	const isWorker = currentUserProfile?.isWorker;

	const companyName = isWorker
		? currentUserProfile?.workerInputCompanyName
			? currentUserProfile?.workerInputCompanyName
			: selectedClientReq4In?.companyName
		: selectedClientReq4In?.companyName;

	// 임금기산일
	const startToEndDate = selectedClientReq4In?.monthlyPay?.startToEndDate;

	// 임금기산일
	const payDay = selectedClientReq4In?.monthlyPay?.payDay;

	// 1일 소정근로시간(주휴수당근거수당)
	const paidHolidayLBTime = selectedClientReq4In?.lawBase?.paidHolidayLBTime;

	// 월 통상임금((평균임금제외) -법정가산수당))
	const monthOrdinaryWage =
		selectedClientReq4In?.calWorkWage?.monthOrdinaryWage;

	// 일당통상임금 합계
	const sumOfCOWDay = selectedClientReq4In?.sumOfCOWDay;
	// ------

	const wageTableCopy = paydocuCopy || paydocuFix ? true : false;
	const clientUid = clientInfo?.id ? clientInfo?.id : '';
	const hostUid = currentUserProfile?.id;
	const workDates = [];
	const monthTotalWorkTime = '';
	const finalPaidWeekObj = '';
	const finalPaidHoliWageTimeSum = '';
	const lawBasePaidWeekObj = '';
	const lawBaseWorkDatesWorkHourThisMonthOnlySum = '';
	const filteredSelectedDays = '';
	const weekBLTimeTWThisMonth = '';

	// 중도퇴사자
	const retiredDate = selectedClientReq4In?.retiredDate
		? selectedClientReq4In?.retiredDate
		: '';
	const workerRetiredDate = retiredDate
		? format(new Date(retiredDate.toString()), 'yyyy-MM-dd')
		: '';
	const workerRetiredYearMonth = retiredDate
		? format(new Date(retiredDate.toString()), 'yyyyMM')
		: '';
	const workerRetiredDateDay = retiredDate
		? format(new Date(retiredDate.toString()), 'dd')
		: '';
	const retiredYearMonthTrue =
		values.thisYear + values.thisMonth === workerRetiredYearMonth
			? true
			: false;
	const isInterOutWorker = isInterOutWorkerF(
		retiredDate,
		values.thisMonth,
		values.thisYear
	);

	const paydocuTaxWageSum = retiredWorkerPaydocu?.deductTaxAnd4In?.taxWageSum;

	function hasWageChanged(paydocuTaxWageSum, valueTaxWageSum) {
		if (!paydocuTaxWageSum) return true; // 기존 데이터가 없으면 변경된 것으로 간주

		const result =
			roundUp(paydocuTaxWageSum / 10) * 10 !==
			roundUp(valueTaxWageSum / 10) * 10
				? true
				: false;
		return result;
	}
	async function handleFormSubmit(e) {
		try {
			let existingPayDocu = selectedPaydocuFirst;
			let existingPayDocuId = selectedPaydocuFirst?.id;

			// selectedPaydocuFirst가 없을 경우 데이터베이스에서 동일한 yearMonth와 workerId를 가진 문서를 검색
			if (!existingPayDocu) {
				const targetYearMonth = values.thisYear + values.thisMonth;
				const q = query(
					collection(db, 'payDocu'),
					where('info.yearMonth', '==', targetYearMonth),
					where('workerInfo.workerId', '==', selectedClientReq4In?.id)
				);

				const querySnapshot = await getDocs(q);

				if (!querySnapshot.empty) {
					// 동일한 문서가 있으면 existingPayDocu에 할당
					const docSnapshot = querySnapshot.docs[0];
					existingPayDocu = docSnapshot.data();
					existingPayDocuId = docSnapshot.id; // Extract the document ID
				}
			}
			// UpdatePayDocuToFirestore를 호출할 조건
			if (existingPayDocu) {
				const canEdit =
					(await authLevel) >= 100 ||
					!existingPayDocu?.isCompanyComformedTime ||
					!wageTableCopy;

				if (!canEdit) {
					alert(
						`저장 error : ${selectedClientReq4In?.worker.name}의 ${values.thisYear}년 ${values.thisMonth}월 급여는 마감되어 수정이 불가능합니다.`
					);
				} else {
					axios
						.post(`${API_BASE_URL}/update-worker-wage`, {
							hostUid,
							values,
							workDates,
							existingPayDocuId, // Pass the document ID here
							initialTax,
							maternityFinalDedectWage,
							monthTotalWorkTime,
							finalPaidWeekObj,
							finalPaidHoliWageTimeSum,
							lawBasePaidWeekObj,
							lawBaseWorkDatesWorkHourThisMonthOnlySum,
							filteredSelectedDays,
							weekBLTimeTWThisMonth,
							clientUid,
						})
						.then(async (response) => {
							// 서버에서 문서 생성 후 Firestore에서 다시 데이터를 불러와 상태를 업데이트
							const targetYearMonth = values.thisYear + values.thisMonth;
							const q = query(
								collection(db, 'payDocu'),
								where('info.yearMonth', '==', targetYearMonth),
								where('workerInfo.workerId', '==', selectedClientReq4In?.id)
							);

							const querySnapshot = await getDocs(q);

							if (!querySnapshot.empty) {
								const docSnapshot = querySnapshot.docs[0];
								selectedPaydocuFirst = docSnapshot.data();
								existingPayDocuId = docSnapshot?.id;
							}

							// 급여 변경 여부 확인 로직
							const wageChanged = hasWageChanged(
								paydocuTaxWageSum,
								valueTaxWageSum
							);

							if (
								retiredYearMonthTrue &&
								(wageChanged || !existingPayDocu?.isHealthCareRecalRedirected)
							) {
								// 급여가 변경되었거나 처음 리다이렉트인 경우 healthCareRecal로 이동

								await updateDoc(doc(db, 'payDocu', existingPayDocuId), {
									isHealthCareRecalRedirected: true,
								});
								await updateDoc(doc(db, 'workers', selectedClientReq4In?.id), {
									isInsureOutRequest: deleteField(),
									isInsureOutRequestDate: deleteField(),
								});
								history.push(`/healthCareRecal/${selectedClientReq4In?.id}`);
							} else {
								// 그 외의 경우 paydocus로 이동
								history.push('/paydocus');
							}
						})
						.catch((error) => {
							// 에러 처리
							console.error('Error:', error);
							toast.error(error.message);
						});
				}
			} else {
				axios
					.post(`${API_BASE_URL}/add-worker-wage`, {
						hostUid,
						values,
						workDates,
						initialTax,
						maternityFinalDedectWage,
						monthTotalWorkTime,
						finalPaidWeekObj,
						finalPaidHoliWageTimeSum,
						lawBasePaidWeekObj,
						lawBaseWorkDatesWorkHourThisMonthOnlySum,
						filteredSelectedDays,
						weekBLTimeTWThisMonth,
						clientUid,
					})
					.then(async (response) => {
						// 서버에서 문서 생성 후 Firestore에서 다시 데이터를 불러와 상태를 업데이트
						const targetYearMonth = values.thisYear + values.thisMonth;
						const q = query(
							collection(db, 'payDocu'),
							where('info.yearMonth', '==', targetYearMonth),
							where('workerInfo.workerId', '==', selectedClientReq4In?.id)
						);

						const querySnapshot = await getDocs(q);

						if (!querySnapshot.empty) {
							const docSnapshot = querySnapshot.docs[0];
							existingPayDocu = docSnapshot.data();
							existingPayDocuId = docSnapshot.id;
						}

						// 급여 변경 여부 확인 로직
						const wageChanged = hasWageChanged(
							paydocuTaxWageSum,
							valueTaxWageSum
						);

						if (
							retiredYearMonthTrue &&
							(wageChanged || !existingPayDocu?.isHealthCareRecalRedirected)
						) {
							// 급여가 변경되었거나 처음 리다이렉트인 경우 healthCareRecal로 이동
							await updateDoc(doc(db, 'payDocu', existingPayDocuId), {
								isHealthCareRecalRedirected: true,
							});
							history.push(`/healthCareRecal/${selectedClientReq4In?.id}`);
						} else {
							// 그 외의 경우 paydocus로 이동
							history.push('/paydocus');
						}
					})
					.catch((error) => {
						// 에러 처리
						console.error('Error:', error);
						toast.error(error.message);
					});
			}

			dispatch(clearFinalRetireHealthCareRecalFee());
			dispatch(clearFinalRetireOldCareRecalFee());
		} catch (error) {
			console.log(error.message);
		}
	}

	//일당 통상임금 포함시 통상시급 설명 바뀌는 함수
	dayOrdIncludeComment4(
		sumOfCOWDay,
		monthOrdinaryWage,
		monthLBTime,
		paidHolidayLBTime
	);

	//해당월 일수
	const daysInMonth = moment(
		`"${values.thisYear}-${values.thisMonth}"`,
		'YYYY-MM'
	).daysInMonth();

	//중도입사자
	//입사일 string
	const enteredDate = selectedClientReq4In?.workerEnteredDate;
	const againContractStartDate = selectedClientReq4In?.againContractStartDate;
	const workerEnteredDate = againContractStartDate
		? format(new Date(againContractStartDate.toString()), 'yyyy-MM-dd')
		: enteredDate
		? format(new Date(enteredDate.toString()), 'yyyy-MM-dd')
		: '';

	const isInterInWorker = isInterInWorkerF(
		enteredDate,
		values.thisMonth,
		values.thisYear
	);
	const contractEndDate = selectedClientReq4In?.contractEndDate
		? format(
				new Date(selectedClientReq4In?.contractEndDate.toString()),
				'yyyy-MM-dd'
		  )
		: '';
	const workerEnteredDateDay = enteredDate
		? format(new Date(enteredDate.toString()), 'dd')
		: '';

	// 입퇴사자 해당월 공제일수(전체 - 재직일수)
	const daysOfInterInAbusent = isInterInWorker
		? daysInMonth - (daysInMonth - workerEnteredDateDay + 1)
		: '';
	const daysOfInterOutAbusent = isInterOutWorker
		? daysInMonth - workerRetiredDateDay
		: '';

	const oneWeekworkdaysCheck =
		selectedClientReq4In?.realWorkTime?.oneWeekworkdaysCheck ||
		selectedClientReq4In?.realWorkTime?.oneWeekworkdaysCheckETLWorker;

	const underInOut5daysWorker =
		(isInterInWorker || isInterOutWorker) && oneWeekworkdaysCheck < 5
			? true
			: false;

	useEffect(() => {
		const underInOut5daysWorker =
			(isInterInWorker || isInterOutWorker) && oneWeekworkdaysCheck < 5;
		setShowAlert(underInOut5daysWorker);
	}, [selectedClientReq4In, values.thisYear, values.thisMonth]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (showAlert) {
			alert(
				worker +
					'의 1주 소정근로일은 ' +
					oneWeekworkdaysCheck +
					'일이고 본 시스템의 중도 입퇴사 자동일할계산은 1주 소정근로 5일 기준입니다.\n\n' +
					'보정이 필요한 경우 자동 일할 계산된 부분과 실제 임금과의 차이는 "지급 조정" 항목을 이용하여 보정하세요.\n\n' +
					'정확한 계산은 본 시스템의 일용직 또는 시급직 급여작업 기능을 이용하시면 편리합니다.'
			);
		}
	}, [showAlert]); // eslint-disable-line react-hooks/exhaustive-deps

	const companySize = clientInfo?.companySize
		? clientInfo?.companySize
		: currentUserProfile?.companySize;

	// 책정된 법정가산수당
	const numOWT = numStringToNumber(selectedClientReq4In?.calWorkWage?.mOWage);
	const numNWT = numStringToNumber(selectedClientReq4In?.calWorkWage?.mNWage);
	const numHBWT = numStringToNumber(selectedClientReq4In?.calWorkWage?.mHBWage);
	const numHOWT = numStringToNumber(selectedClientReq4In?.calWorkWage?.mHOWage);
	const numHNWT = numStringToNumber(selectedClientReq4In?.calWorkWage?.mHNWage);

	// 책정된 법정가산수당 합계
	const sumOfLawOverWage = numOWT + numNWT + numHBWT + numHOWT + numHNWT;

	const [shouldRunEffect, setShouldRunEffect] = useState(false);

	const retiredYearMonth = moment(
		selectedClientReq4In?.retiredDate ? selectedClientReq4In?.retiredDate : ''
	).format('YYYYMM');
	const thisYearMonth = values.thisYear + values.thisMonth;
	const retiredMonthYes = retiredYearMonth === thisYearMonth ? true : false;

	useEffect(() => {
		if (
			selectedClientReq4In?.maternityLeaveStartDate &&
			selectedClientReq4In?.maternityLeaveEndDate
		) {
			setShouldRunEffect(true);
		} else {
			setShouldRunEffect(false);
		}
	}, [
		selectedClientReq4In?.maternityLeaveStartDate,
		selectedClientReq4In?.maternityLeaveEndDate,
	]);
	// cloud Function으로 날짜를 보낼때는 날짜 형식을 아래와 같이 해야함.

	const year = values?.thisYear;
	const month = values?.thisMonth;

	useEffect(() => {
		if (selectedClientReq4In?.id) {
			setMaternityFinalDedectWage([
				'',
				'',
				'',
				'',
				false,
				Array(0),
				null,
				'',
				0,
			]);
		}
	}, [selectedClientReq4In?.id]);

	useEffect(() => {
		let isMounted = true; // 마운트 상태를 추적하는 플래그

		if (!shouldRunEffect || !selectedClientReq4In?.id) return;

		const maternityLeaveStartDate = moment
			.utc(selectedClientReq4In?.maternityLeaveStartDate)
			.utcOffset('+09:00')
			.format('YYYY-MM-DD');
		const maternityLeaveEndDate = moment
			.utc(selectedClientReq4In?.maternityLeaveEndDate)
			.utcOffset('+09:00')
			.format('YYYY-MM-DD');
		const maternityLeaveType = selectedClientReq4In?.maternityLeaveType;

		const postData = {
			companySize,
			maternityLeaveStartDate,
			maternityLeaveEndDate,
			maternityLeaveType,
			year,
			month,
			monthOrdinaryWage,
			daysInMonth,
			sumOfLawOverWage,
		};

		axios
			.post(`${API_BASE_URL}/calculateMaternityWage`, postData)
			.then((response) => {
				if (isMounted) {
					setMaternityFinalDedectWage(response.data);
				}
			})
			.catch((error) => {
				console.error('Error:', error);
			});

		// 클린업 함수
		return () => {
			isMounted = false;
		};
	}, [
		companySize,
		selectedClientReq4In?.maternityLeaveStartDate,
		selectedClientReq4In?.maternityLeaveEndDate,
		selectedClientReq4In?.maternityLeaveType,
		year,
		month,
		monthOrdinaryWage,
		daysInMonth,
		sumOfLawOverWage,
		shouldRunEffect,
		selectedClientReq4In?.id, // 추가된 의존성
	]);

	function selfDeductButtonClickHandler() {
		setInputTax(false);
		setInitialTax(false);
		alert('공제하지 않는 항목은 반드시 "0"을 입력하세요.');
	}

	function plusRegularBonusCommentAlert() {
		toast(
			'대법 “정기상여금에 ‘재직자 조건’ 붙어도 통상임금 인정”(2022년4월28일-2019다238053))참조'
		);
		toast(
			'평균임금인지 여부에 대해 노무사와 취업규칙, 근로계약서, 단체협약서(있는 경우) 등에 대해 상의하시고 평균임금인 경우 입력하세요.'
		);
	}

	function plusAnnualLeavesCommentAlert() {
		toast(
			'퇴직년도에 발생한 연차 중 퇴직일까지 사용하지 못한 연차는 퇴직월에 자동 생성되는 제일 오른쪽 퇴직 미사용 연차수당 칸에 입력하세요.'
		);
	}

	function plusRetiredAnnualLeavesCommentAlert() {
		toast(
			'퇴직으로 인해 발생한 미사용 연차수당은 평균임금에 산입되지 않습니다.'
		);
	}

	function plusSpecialRandomWageAlert() {
		toast(
			'직원의 근로제공과 관련없는 경영성과급, Profit Sharing, 특별상여금, 은혜적 금품을 입력하세요. 평균임금에서 제외툅니다.'
		);
	}

	function plusLastMonthAverWageAlert() {
		toast('과거월의 연장근로 누락 등의 평균임금 계산 착오인 경우 입력하세요.');
		toast(
			'기본급 인상 등 통상임금 소급 인상의 경우 직원정보에서 통상임금을 수정하세요.'
		);
	}

	function plusPersonalIncentiveAlert() {
		toast(
			'개인 실적 인센티브는 평균임금에 포함되는 반면, 팀 기준 또는 전사 기준 인센티브 등 개인 근로제공과 직접 연관없는 인센티브는 평균임금에서 제외됩니다.'
		);
		toast(
			'인센티브 성격과 관련해서 노무사와 취업규칙, 근로계약서, 단체협약서(있는 경우) 등에 대해 상의하시고 개인 실적인센티브인 경우 입력하세요.'
		);
	}

	function initialTaxButtonClickHandler() {
		setInputTax(true);
		setInitialTax(true);
		toast('수기입력된 세금을 법정으로 계산합니다.');
	}

	const annualLeavesIncludeMonthWageTime =
		selectedClientReq4In?.annualLeavesIncludeMonthWageTime;
	const annualLeavesIncludeMonthWage =
		selectedClientReq4In?.annualLeavesIncludeMonthWage;

	const isRetireMonth =
		retiredDate && moment(new Date(retiredDate)).format('YYYYMM') === yearMonth;

	function payPaperAddTimeYTOpen() {
		dispatch(openModal({ modalType: 'PayPaperAddTime' }));
	}

	function payPaperAddMoneyYTOpen() {
		dispatch(openModal({ modalType: 'PayPaperAddMoney' }));
	}

	function payPaperMinusTimeYTOpen() {
		dispatch(openModal({ modalType: 'PayPaperMinusTime' }));
	}

	function payPaperRetireYTOpen() {
		dispatch(openModal({ modalType: 'PayPaperRetire' }));
	}

	function payPaperMinusMoneyYTOpen() {
		dispatch(openModal({ modalType: 'PayPaperMinusMoney' }));
	}

	const { clientId } = useParams();
	useEffect(() => {
		setMaternityFinalDedectWage(['', '', '', '', false, Array(0), null, '', 0]);

		return history.listen((location) => {
			setMaternityFinalDedectWage([
				'',
				'',
				'',
				'',
				false,
				Array(0),
				null,
				'',
				0,
			]);
		});
	}, [history, clientId]);

	const daylyTimePay = selectedClientReq4In?.daylyPay?.timeWage
		? numStringToNumber(selectedClientReq4In?.daylyPay?.timeWage)
		: 0;
	const isDayOrTimeWorker =
		selectedClientReq4In?.dayWorker?.daylyPay || daylyTimePay ? true : false;

	//산전후휴가기간 표시
	const maternityLeaveStartDate = selectedClientReq4In?.maternityLeaveStartDate
		? moment(selectedClientReq4In?.maternityLeaveStartDate).format('YYYY-MM-DD')
		: null;
	const maternityLeaveEndDate = selectedClientReq4In?.maternityLeaveEndDate
		? moment(selectedClientReq4In?.maternityLeaveEndDate).format('YYYY-MM-DD')
		: null;
	const momentWithRange = extendMoment(moment);
	const start = momentWithRange(selectedClientReq4In?.maternityLeaveStartDate);
	const end = momentWithRange(
		selectedClientReq4In?.maternityLeaveEndDate
	).endOf('month');
	const range = momentWithRange.range(start, end);
	const maternityLeaveMonths = Array.from(range.by('month')).map((m) =>
		m.format('YYYY-MM')
	);

	// 연도와 월을 모두 고려하여 출산 휴가 기간에 포함되는지 확인
	function isThisMonthInMaternityLeavePeriod(
		currentMonth,
		currentYear,
		maternityLeaveMonths
	) {
		// 연도와 월을 'YYYY-MM' 형식으로 표현
		const currentPeriod = `${currentYear}-${currentMonth}`;
		// 출산 휴가 기간의 월들이 'YYYY-MM' 형식으로 변환됩니다.
		const formattedMaternityLeaveMonths = maternityLeaveMonths.map(
			(yearMonth) => {
				// 출산 휴가 시작 월의 연도 추출
				// 해당 월의 연도 계산 (월이 12월을 넘어가면 연도 +1)
				return `${yearMonth}`;
			}
		);
		// 현재 월/연도 조합이 출산 휴가 월 목록에 포함되는지 확인
		return formattedMaternityLeaveMonths.includes(currentPeriod);
	}
	const isTodayInMaternityRange = isThisMonthInMaternityLeavePeriod(
		values?.thisMonth,
		values.thisYear,
		maternityLeaveMonths
	);
	const isTodayInMaternityRangePerson =
		isTodayInMaternityRange && maternityLeaveStartDate !== null
			? `출산전후휴가자 [${maternityLeaveStartDate}~${maternityLeaveEndDate}]`
			: null;

	if (loading || (!selectedClientReq4In && !error))
		return <LoadingComponent content='Loading 임금명세서 ...' />;
	if (error) return <Redirect to='/error' />;

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}

			<Grid>
				<Grid.Column width={16}>
					<ClientReqDetailedHeader4In
						selectedClientReq4In={selectedClientReq4In}
						companyName={companyName}
						clientReqs4In={clientReqs4In}
						isDayOrTimeWorker={isDayOrTimeWorker}
						daylyTimePay={daylyTimePay}
					/>
					<ClientWageTableTopInfo isInterInWorker={isInterInWorker} />
					<ClientReqDLCJudgeWageTable
						paydocuCopy={paydocuCopy}
						selectedClientReq4In={selectedClientReq4In}
						values={values}
						handleFormSubmit={handleFormSubmit}
						selectedPaydocuFirst={selectedPaydocuFirst}
					/>
					<ClientWageTableTopButton
						initialTaxButtonClickHandler={initialTaxButtonClickHandler}
						selfDeductButtonClickHandler={selfDeductButtonClickHandler}
					/>

					<br />
					<div id='wageTable'>
						<form onSubmit={handleFormSubmit} id='myForm'>
							<Table border='1' width='100%' align='center' id='table-to-xls'>
								<ClientReqDLCHeadWageTable
									isExceptTimeLawWorker={isExceptTimeLawWorker}
									companyName={companyName}
									inputWageStyle={inputWageStyle}
									handelInputChange={handelInputChange}
									thisMonth={values.thisMonth}
									thisYear={values.thisYear}
									worker={worker}
									selectedPaydocu={paydocus[0]}
								/>
								<Table.Body>
									<ClientWageTableTop1BasicInfo
										retiredDate={retiredDate}
										worker={worker}
										isTodayInMaternityRangePerson={
											isTodayInMaternityRangePerson
										}
										centerStyle={centerStyle}
										monthOWT={monthOWT}
										monthNWT={monthNWT}
										monthHoliBasicTime={monthHoliBasicTime}
										monthOWTHoli={monthOWTHoli}
										monthNWTHoli={monthNWTHoli}
										annualLeavesIncludeMonthWageTime={
											annualLeavesIncludeMonthWageTime
										}
										decryptoData={decryptoData}
										monthLBTime={monthLBTime}
										hourOrdinaryWage={hourOrdinaryWage}
										workerEnteredDate={workerEnteredDate}
										workerRetiredDate={workerRetiredDate}
										startToEndDate={startToEndDate}
										payDay={payDay}
									/>

									<HrManage
										selectedClientReq4In={selectedClientReq4In}
										currentUserProfile={currentUserProfile}
										clientInfo={clientInfo}
										centerStyle={centerStyle}
										values={values}
										handelInputChange={handelInputChange}
									/>
									<ClientWageTableTop2TimeDeduct
										payPaperMinusTimeYTOpen={payPaperMinusTimeYTOpen}
										worker={worker}
										numOfWorkers={numOfWorkers}
										isExceptTimeLawWorker={isExceptTimeLawWorker}
										weekLBTime={weekLBTime}
										centerStyle={centerStyle}
										payPaperAddTimeYTOpen={payPaperAddTimeYTOpen}
									/>
									<ClientReqDLCWageTableTimeDetail
										isExceptTimeLawWorker={isExceptTimeLawWorker}
										sumOfCOWDay={sumOfCOWDay}
										absentDays={values.absentDays}
										absentPaidHoliDays={values.absentPaidHoliDays}
										absentTimes={values.absentTimes}
										workingDays={values.workingDays}
										handelInputChange={handelInputChange}
										hourOrdinaryWage={hourOrdinaryWage}
										centerStyle={centerStyle}
										inputTimeStyle={inputTimeStyle}
										plusMonthOWT={values.plusMonthOWT}
										plusMonthNWT={values.plusMonthNWT}
										plusMonthHoliBasicTime={values.plusMonthHoliBasicTime}
										plusMonthOWTHoli={values.plusMonthOWTHoli}
										plusMonthNWTHoli={values.plusMonthNWTHoli}
										usedAnnualLeaves={values.usedAnnualLeaves}
										numOfWorkers={numOfWorkers}
										weekLBTime={weekLBTime}
									/>
									<ClientWageTableTop3TimeExplain
										values={values}
										worker={worker}
										handelInputChange={handelInputChange}
										centerStyle={centerStyle}
										payPaperAddTimeYTOpen={payPaperAddTimeYTOpen}
										centerStyleBlue={centerStyleBlue}
										underInOut5daysWorker={underInOut5daysWorker}
										centerStyleInOutBack={centerStyleInOutBack}
										perDayMealTaxFree={perDayMealTaxFree}
										centerStyleTeal={centerStyleTeal}
										isRetireMonth={isRetireMonth}
										payPaperAddMoneyYTOpen={payPaperAddMoneyYTOpen}
									/>
									<ClientReqDLCWageTableAddWageDetail
										isRetireMonth={isRetireMonth}
										handelInputChange={handelInputChange}
										centerStyle={centerStyle}
										inputTimeStyle={inputTimeStyle}
										plusRegularBonus={values.plusRegularBonus}
										plusIrregularBonus={values.plusIrregularBonus}
										plusUnusedAnnualLeavesDays={
											values.plusUnusedAnnualLeavesDays
										}
										plusRetiredUnusedAnnualLeavesWage={
											values.plusRetiredUnusedAnnualLeavesWage
										}
										plusSpecialRandomWage={values.plusSpecialRandomWage}
										plusLastMonthAverWage={values.plusLastMonthAverWage}
										plusPersonalIncentive={values.plusPersonalIncentive}
										plusAnnualLeavesCommentAlert={plusAnnualLeavesCommentAlert}
										plusRetiredAnnualLeavesCommentAlert={
											plusRetiredAnnualLeavesCommentAlert
										}
										plusRegularBonusCommentAlert={plusRegularBonusCommentAlert}
										plusLastMonthAverWageAlert={plusLastMonthAverWageAlert}
										plusPersonalIncentiveAlert={plusPersonalIncentiveAlert}
										plusSpecialRandomWageAlert={plusSpecialRandomWageAlert}
										perDayMealTaxFree={perDayMealTaxFree}
										plusIrregularDayMealTFDays={
											values.plusIrregularDayMealTFDays
										}
									/>
									<ClientWageTableTop4DeductExplain
										values={values}
										worker={worker}
										handelInputChange={handelInputChange}
										centerStyle={centerStyle}
										isRetireMonth={isRetireMonth}
										payPaperRetireYTOpen={payPaperRetireYTOpen}
										payPaperMinusMoneyYTOpen={payPaperMinusMoneyYTOpen}
									/>
									<ClientReqDLCWageTableAddDeductWageDetail
										handelInputChange={handelInputChange}
										centerStyle={centerStyle}
										inputTimeStyle={inputTimeStyle}
										plusDeductHealthCare={values.plusDeductHealthCare}
										plusDeductOldCare={values.plusDeductOldCare}
										plusDeductWorkTax={values.plusDeductWorkTax}
										plusDeductWorkRegionTax={values.plusDeductWorkRegionTax}
										plusDeductDuru={values.plusDeductDuru}
										plusMinus4Ins={values.plusMinus4Ins}
										plusDeductWorkerCompanyLoan={
											values.plusDeductWorkerCompanyLoan
										}
										dataOfFinalRetireHealthCareRecalFee={
											dataOfFinalRetireHealthCareRecalFee
										}
										dataOfFinalRetireOldCareRecalFee={
											dataOfFinalRetireOldCareRecalFee
										}
										isRetireMonth={isRetireMonth}
										savedFinalRetireHealthCareRecalFee={
											selectedPaydocuFirst?.deductTaxAnd4In
												.finalRetireHealthCareRecalFee
										}
										savedFinalRetireOldCareRecalFee={
											selectedPaydocuFirst?.deductTaxAnd4In
												.finalRetireOldCareRecalFee
										}
									/>
									<ClientWageTableTop5InsureExplain
										values={values}
										numOfWorkers={numOfWorkers}
										handelInputChange={handelInputChange}
										centerStyle={centerStyle}
									/>

									<ClientReqDLCWageTablePayDetail
										retiredMonthYes={retiredMonthYes}
										workerEnteredDate={workerEnteredDate}
										contractEndDate={contractEndDate}
										sumOfLawOverWage={sumOfLawOverWage}
										maternityFinalDedectWage={maternityFinalDedectWage}
										selectedPaydocuFirst={selectedPaydocuFirst}
										healthInsureOver60={healthInsureOver60}
										values={values}
										annualLeavesIncludeMonthWage={annualLeavesIncludeMonthWage}
										isExceptTimeLawWorker={isExceptTimeLawWorker}
										initialTax={initialTax}
										isInterInWorker={isInterInWorker}
										thisYear={values.thisYear}
										thisMonth={values.thisMonth}
										handelInputChange={handelInputChange}
										selectedClientReq4In={selectedClientReq4In}
										numOfWorkers={numOfWorkers}
										weekLBTime={weekLBTime}
										hourOrdinaryWage={hourOrdinaryWage}
										inputWageStyle={inputWageStyle}
										centerStyle={centerStyle}
										inputCommentStyle={inputCommentStyle}
										inputCommentStyleWide={inputCommentStyleWide}
										daysOfInterInAbusent={daysOfInterInAbusent}
										daysOfInterOutAbusent={daysOfInterOutAbusent}
										plusMonthOWT={values.plusMonthOWT}
										plusMonthNWT={values.plusMonthNWT}
										plusMonthHoliBasicTime={values.plusMonthHoliBasicTime}
										plusMonthOWTHoli={values.plusMonthOWTHoli}
										plusMonthNWTHoli={values.plusMonthNWTHoli}
										workingDays={values.workingDays}
										cAWage5={values.cAWage5}
										cAWEName5={values.cAWEName5}
										cAWage5Comment={values.cAWage5Comment}
										cAWage6={values.cAWage6}
										cAWEName6={values.cAWEName6}
										cAWage6Comment={values.cAWage6Comment}
										cAWage7={values.cAWage7}
										cAWEName7={values.cAWEName7}
										cAWage7Comment={values.cAWage7Comment}
										deductEtcName1={values.deductEtcName1}
										deductEtcAWage1={values.deductEtcAWage1}
										deductEtcWage1Comment={values.deductEtcWage1Comment}
										deductEtcName2={values.deductEtcName2}
										deductEtcAWage2={values.deductEtcAWage2}
										deductEtcWage2Comment={values.deductEtcWage2Comment}
										deductEtcName3={values.deductEtcName3}
										deductEtcAWage3={values.deductEtcAWage3}
										deductEtcWage3Comment={values.deductEtcWage3Comment}
										userFixedCAWage1={values.userFixedCAWage1}
										userFixedCAWage1Name={values.userFixedCAWage1Name}
										userFixedCAWage1Comment={values.userFixedCAWage1Comment}
										userFixedCAWage2={values.userFixedCAWage2}
										userFixedCAWage2Name={values.userFixedCAWage2Name}
										userFixedCAWage2Comment={values.userFixedCAWage2Comment}
										userFixedCAWage3={values.userFixedCAWage3}
										userFixedCAWage3Name={values.userFixedCAWage3Name}
										userFixedCAWage3Comment={values.userFixedCAWage3Comment}
										userFixedCAWage4={values.userFixedCAWage4}
										userFixedCAWage4Name={values.userFixedCAWage4Name}
										userFixedCAWage4Comment={values.userFixedCAWage4Comment}
										userFixedCAWageTF1={values.userFixedCAWageTF1}
										userFixedCAWageTF1Name={values.userFixedCAWageTF1Name}
										userFixedCAWageTF1Comment={values.userFixedCAWageTF1Comment}
										userFixedCAWageTF2={values.userFixedCAWageTF2}
										userFixedCAWageTF2Name={values.userFixedCAWageTF2Name}
										userFixedCAWageTF2Comment={values.userFixedCAWageTF2Comment}
										userFixedCAWageTF3={values.userFixedCAWageTF3}
										userFixedCAWageTF3Name={values.userFixedCAWageTF3Name}
										userFixedCAWageTF3Comment={values.userFixedCAWageTF3Comment}
										userFixedCAWageTF4={values.userFixedCAWageTF4}
										userFixedCAWageTF4Name={values.userFixedCAWageTF4Name}
										userFixedCAWageTF4Comment={values.userFixedCAWageTF4Comment}
										userFixedTax={values.userFixedTax}
										userFixedRegionTax={values.userFixedRegionTax}
										userFixedHealthCare={values.userFixedHealthCare}
										userFixedOldCare={values.userFixedOldCare}
										userFixedPension={values.userFixedPension}
										userFixedNoJobInsurance={values.userFixedNoJobInsurance}
										absentDays={values.absentDays}
										absentPaidHoliDays={values.absentPaidHoliDays}
										absentTimes={values.absentTimes}
										daysInMonth={daysInMonth}
										paidHolidayLBTime={paidHolidayLBTime}
										monthOrdinaryWage={monthOrdinaryWage}
										inputTax={inputTax}
										plusRegularBonus={values.plusRegularBonus}
										plusIrregularBonus={values.plusIrregularBonus}
										plusUnusedAnnualLeavesDays={
											values.plusUnusedAnnualLeavesDays
										}
										plusRetiredUnusedAnnualLeavesWage={
											values.plusRetiredUnusedAnnualLeavesWage
										}
										plusSpecialRandomWage={values.plusSpecialRandomWage}
										plusPersonalIncentive={values.plusPersonalIncentive}
										plusLastMonthAverWage={values.plusLastMonthAverWage}
										plusRegularBonusComment={values.plusRegularBonusComment}
										plusIrregularBonusComment={values.plusIrregularBonusComment}
										plusUnusedAnnualLeavesWageComment={
											values.plusUnusedAnnualLeavesWageComment
										}
										plusPersonalIncentiveComment={
											values.plusPersonalIncentiveComment
										}
										plusLastMonthAverWageComment={
											values.plusLastMonthAverWageComment
										}
										plusDeductHealthCare={values.plusDeductHealthCare}
										plusDeductOldCare={values.plusDeductOldCare}
										plusDeductWorkTax={values.plusDeductWorkTax}
										plusDeductWorkRegionTax={values.plusDeductWorkRegionTax}
										plusDeductDuru={values.plusDeductDuru}
										plusMinus4Ins={values.plusMinus4Ins}
										plusDeductWorkerCompanyLoan={
											values.plusDeductWorkerCompanyLoan
										}
										plusDeductHealthCareComment={
											values.plusDeductHealthCareComment
										}
										plusDeductOldCareComment={values.plusDeductOldCareComment}
										plusDeductWorkTaxComment={values.plusDeductWorkTaxComment}
										plusDeductWorkRegionTaxComment={
											values.plusDeductWorkRegionTaxComment
										}
										plusDeductDuruComment={values.plusDeductDuruComment}
										plusMinus4InsComment={values.plusMinus4InsComment}
										plusDeductWorkerCompanyLoanComment={
											values.plusDeductWorkerCompanyLoanComment
										}
										finalRetireHealthCareRecalFee={
											values?.finalRetireHealthCareRecalFee
										}
										finalRetireOldCareRecalFee={
											values?.finalRetireOldCareRecalFee
										}
										perDayMealTaxFree={perDayMealTaxFree}
										plusIrregularDayMealTFDays={
											values.plusIrregularDayMealTFDays
										}
										setValueTaxWageSum={setValueTaxWageSum}
									/>
									<ClientWageTableTop6EndExplain
										values={values}
										worker={worker}
										handelInputChange={handelInputChange}
										centerStyle={centerStyle}
										numOfWorkers={numOfWorkers}
										weekLBTime={weekLBTime}
										isExceptTimeLawWorker={isExceptTimeLawWorker}
										paidHolidayLBTime={paidHolidayLBTime}
										sumOfCOWDay={sumOfCOWDay}
										monthOrdinaryWage={monthOrdinaryWage}
										mTPCTime={mTPCTime}
										monthLBTime={monthLBTime}
										hourOrdinaryWage={hourOrdinaryWage}
										today={today}
									/>
								</Table.Body>
							</Table>
						</form>
					</div>
					<ClientReqDLCJudgeWageTable
						paydocuCopy={paydocuCopy}
						selectedClientReq4In={selectedClientReq4In}
						values={values}
						handleFormSubmit={handleFormSubmit}
						selectedPaydocuFirst={selectedPaydocuFirst}
					/>
					<br />
					<hr />
					<hr />
				</Grid.Column>
			</Grid>
		</>
	);
}
